import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Grid,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import Hidden from "@mui/material/Hidden";
import SolutionRankingsTableRow from "./solution-rankings-table-row";
import TableSearchField from "../../misc/table-search-field";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "20px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  button: {
    marginTop: "10px",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export default function SolutionRankingsTable(props) {
  const classes = useStyles();

  const {
    page,
    rowsPerPage,
    count,
    setSearch,
    searchRefs,
    searchCols,
    onChangePage,
    onChangeRowsPerPage,
    rankings,
    loading,
  } = props;

  const [expandAll, setExpandAll] = React.useState(false);

  return (
    <React.Fragment>
      <Grid container direction={"row"} spacing={1}>
        <Grid item>
          <Button
            className={classes.button}
            variant={"contained"}
            onClick={() => setExpandAll(!expandAll)}
          >
            {expandAll ? "Collapse All" : "Expand All Rows"}
          </Button>
        </Grid>
      </Grid>
      <TableContainer className={classes.table}>
        <TablePagination
          component="div"
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ paddingBottom: "0px" }}></TableCell>
              <TableCell style={{ minWidth: "400px" }}> Benchmark </TableCell>
              <TableCell style={{ minWidth: "130px" }}> Best User </TableCell>
              <Hidden lgDown>
                <TableCell> Country </TableCell>
                <TableCell> Organization </TableCell>
              </Hidden>
              <TableCell style={{ minWidth: "70px" }}> Submissions </TableCell>
            </TableRow>
            {/* Added Search Field Row */}
            <TableRow>
              <TableCell colSpan={searchCols || 5}>
                <TableSearchField
                  setSearch={setSearch}
                  reference={searchRefs?.benchmark_id}
                />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            style={{
              position: "relative",
            }}
          >
            {
              <Backdrop
                style={{ position: "absolute", zIndex: 1, opacity: 0.3 }}
                open={loading}
              >
                <CircularProgress />
              </Backdrop>
            }
            {rankings.map((ranking) => {
              return (
                <SolutionRankingsTableRow
                  key={`${ranking.benchmark_id}`}
                  ranking={ranking}
                  expandAll={expandAll}
                />
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </TableContainer>
    </React.Fragment>
  );
}
