import React from "react";
import {
  Typography,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";
import LargeLayout from "../../components/layouts/large-layout";
import baseStyles from "../../css/const";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import EmailIcon from "@mui/icons-material/Email";

export default function BecomeAPartnerPage() {
  const classes = baseStyles();

  useDocTitle("Become a Partner");
  useTracker();

  return (
    <LargeLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1" gutterBottom>
            Becoming a Partner
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Join us in shaping the future of open-source software for autonomous
            driving!
          </Typography>
          <Typography variant="body1" paragraph>
            At CommonRoad, we believe in the power of collaboration and
            innovation. We are the largest open-source benchmark and planning
            suite for autonomous driving and are eager for your contribution. By
            becoming a partner, you will have the opportunity to contribute to
            cutting-edge research and open-source software in motion planning
            and safe autonomous driving, share your expertise, and be part of a
            vibrant community dedicated to advancing autonomous driving
            technologies.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              Why Partner with Us?
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="body1">
                      <strong>Recognition:</strong> Take the visibility of your
                      research and code to the next level.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="body1">
                      <strong>Power Up your Research:</strong> By basing your
                      research on CommonRoad you can use our vast and well-known
                      tools for evaluation, visualization and development across
                      your projects seamlessly.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="body1">
                      <strong>Networking Opportunities:</strong> Connect with
                      other CommonRoad users and the devs and contribute share
                      and discuss your ideas.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="body1">
                      <strong>Coding Experience:</strong> Improve your coding
                      skills by incorporating it into the largest,
                      longest-living open-source motion planning suite and learn
                      from the devs and other partners.
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            How to Get Involved
          </Typography>
          <Typography variant="body1" paragraph>
            Becoming a partner is easy! Simply reach out to us at{" "}
            <a href="mailto:commonroad@lists.lrz.de">commonroad@lists.lrz.de</a>{" "}
            with a link to your code on GitHub. We look forward to collaborating
            with you and driving innovation together!
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ textAlign: "center", mt: 3 }}>
          <Button
            className={`${classes.button} ${classes.buttonCreate}`}
            startIcon={<EmailIcon />}
            variant="contained"
            href="mailto:commonroad@lists.lrz.de"
            size="large"
          >
            Contact Us to Become a Partner
          </Button>
        </Grid>
      </Grid>
    </LargeLayout>
  );
}
