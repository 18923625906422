import CompetitionsService from "../../services/api/competitions-service";

const getCompetitionInfo = async (year = null, latest = false) => {
  const response = await CompetitionsService.getInfo(year, latest);
  return response.data;
};

const navbarLinksStatic = [
  {
    text: "Getting Started",
    type: "button",
    linkType: "inner",
    to: "/getting-started",
  },
  {
    text: "Benchmarks",
    type: "menu",
    menuItems: [
      {
        text: "Scenario Selection Tool",
        linkType: "inner",
        to: "/scenarios",
      },
      {
        text: "Benchmark Leaderboards",
        linkType: "inner",
        to: "/solutions/ranking",
      },
      {
        text: "Challenges",
        linkType: "inner",
        to: "/challenges",
      },
      {
        text: "Datasets",
        linkType: "inner",
        to: "/datasets",
      },
      {
        text: "Scenario Repository",
        linkType: "outer",
        to: "https://gitlab.lrz.de/tum-cps/commonroad-scenarios",
      },
      {
        text: "Scenario Format Documentation 2020a (.pdf)",
        linkType: "outer",
        to: "https://gitlab.lrz.de/tum-cps/commonroad-scenarios/-/blob/master/documentation/XML_commonRoad_2020a.pdf",
      },
      {
        text: "XML Schema Definition 2020a (.xsd)",
        linkType: "outer",
        to: "https://gitlab.lrz.de/tum-cps/commonroad-scenarios/-/blob/master/documentation/XML_commonRoad_XSD_2020a.xsd",
      },
      {
        text: "Traffic Rule Format Documentation (.pdf)",
        linkType: "outer",
        to: "https://gitlab.lrz.de/tum-cps/traffic-rules/-/blob/master/traffic_rules.pdf",
      },
    ],
  },
  {
    text: "Community",
    type: "menu",
    menuItems: [
      {
        text: "Forum",
        linkType: "outer",
        to: process.env.REACT_APP_FORUM_URL,
      },
      {
        text: "Publications",
        linkType: "inner",
        to: "/publications",
      },
      {
        text: "Teaching Material",
        linkType: "inner",
        to: "/teaching",
      },
      {
        text: "Tutorial: Host a Challenge",
        linkType: "inner",
        to: "/challenges/tutorial",
      },
      {
        text: "Internship Opportunities",
        linkType: "inner",
        to: "/internship",
      },
      {
        text: "Team",
        linkType: "inner",
        to: "/team",
      },
      {
        text: "External Projects",
        linkType: "inner",
        to: "/external-projects",
      },
      {
        text: "Become a Partner",
        linkType: "inner",
        to: "/partner",
      },
    ],
  },
];

const getNavigationLinks = async () => {
  const latestCompetition = await getCompetitionInfo(null, true);
  const archiveCompetitions = await getCompetitionInfo(null, false);

  const competitionLinks = archiveCompetitions.map((competition) => ({
    text: `Competition ${competition.year}`,
    type: "button",
    linkType: "inner",
    to: `/competition/${competition.year}/announcement`,
  }));

  const navbarLinks = [
    ...navbarLinksStatic,
    {
      text: `Competition ${latestCompetition.year}`,
      type: "menu",
      menuItems: [
        {
          text: "Announcement: Commonroad Motion Planning Competition for Autonomous Vehicles",
          type: "button",
          linkType: "inner",
          to: `/competition/${latestCompetition.year}/announcement`,
        },
        {
          text: "Rules and Details",
          type: "button",
          linkType: "inner",
          to: `/competition/${latestCompetition.year}/details`,
        },
        {
          text: "Organizers",
          type: "button",
          linkType: "inner",
          to: `/competition/${latestCompetition.year}/organizers`,
        },
        ...(latestCompetition.activePhase === 2
          ? [
            {
              text: "Leaderboard Phase 1",
              type: "button",
              linkType: "inner",
              to: `/challenges/id/${latestCompetition.phase1}`,
            },
            {
              text: "Leaderboard Phase 2",
              type: "button",
              linkType: "inner",
              to: `/challenges/id/${latestCompetition.phase2}`,
            },
          ]
          : [
            {
              text: "Leaderboard Phase 1",
              type: "button",
              linkType: "inner",
              to: `/challenges/id/${latestCompetition.phase1}`,
            },
          ]),
        {
          text: "Archive",
          type: "menu",
          menuItems: competitionLinks,
        },
      ],
    },
  ];

  return navbarLinks;
};

export default getNavigationLinks;
