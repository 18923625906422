import React, { useState } from "react";
import ScenariosContainer from "../../scenario/scenarios-container";

export default function ChallengeScenarios(props) {
  const [selected, setSelected] = useState([]);

  return (
    <React.Fragment>
      <ScenariosContainer
        activityId={props.id}
        downloadable
        selectable
        selected={selected}
        setSelected={setSelected}
      />
    </React.Fragment>
  );
}
